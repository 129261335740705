<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <p>
          At first glance, Question Answering and Machine Reading Comprehension
          over Documents is simply the KIE scenario where a question in natural
          language replaced a property name. More differences become evident
          when one notices that QA and MRC involve an open set of questions and
          various document types. Consequently, there is pressure to interpret
          the question and to possess better generalization abilities.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-xxl-8">
        <DocumentQA :list="list"></DocumentQA>
      </div>

      <div class="col-xxl-4">
        <StatWidget7
          category="Document QA"
          :progress="list.map(a => a.qa).reverse()"
          color="warning"
        ></StatWidget7>
        <Paper></Paper>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DocumentQA from "@/view/content/widgets/list/DocumentQA.vue";
import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
import Paper from "@/view/content/widgets/due/Paper.vue";
import { mapState } from "vuex";

export default {
  name: "document-qa",
  components: {
    DocumentQA,
    StatWidget7,
    Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaderboard" },
      { title: "Document QA" }
    ]);
  },
  computed: {
    ...mapState({
      list: state =>
        state.auth.leaderboard
          .filter(item => item.qa > 0)
          .sort((a, b) => {
            return a.qa - b.qa;
          })
          .reverse()
    })
  }
};
</script>
