<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Leaderboard</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm"
          >Best-performing Document Understanding models in the category of
          Document Question Answering.</span
        >
      </h3>
      <div class="card-toolbar">
        <!-- <a
          href="/analysis"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
          >Analysis</a
        > -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 350px" class="pl-7">
                  <span class="text-dark-75">model</span>
                </th>
                <th style="min-width: 120px">Document VQA</th>
                <th style="min-width: 100px">Infographics VQA</th>
                <th style="min-width: 100px">Category score</th>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0 py-8">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label">
                          <img
                            src="/media/svg/avatars/t5.png"
                            class="h-75 align-self-end"
                            alt=""
                          />
                        </span>
                      </div>
                      <div>
                        <router-link
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          :to="{ name: 'details', params: { id: item.id } }"
                        >
                          {{ item.name }}
                        </router-link>
                        <span class="text-muted font-weight-bold d-block">{{
                          item.desc
                        }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <!-- <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.qa }}</span
                    > -->
                    <span class="text-muted font-weight-bold">
                      {{ item.docvqa || "n/a" }}
                      <sup v-if="item.docvqa">
                        &#xb1;{{ item.docvqa_std }}
                      </sup>
                    </span>
                  </td>
                  <td>
                    <!-- <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.amount }}</span
                    > -->
                    <span class="text-muted font-weight-bold">
                      {{ item.infographics || "n/a" }}
                      <sup v-if="item.infographics">
                        &#xb1;{{ item.infographics_std }}
                      </sup>
                    </span>
                  </td>
                  <td>
                    <!-- <img
                      src="/media/logos/stars.png"
                      alt="image"
                      style="width: 5rem"
                    /> -->
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.qa }}</span
                    >
                    <!-- <span class="text-muted font-weight-bold d-block">{{
                      item.overall
                    }}</span> -->
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      :to="{ name: 'details', params: { id: item.id } }"
                    >
                      Details
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "document-qa",
  props: ["list"]
};
</script>
